import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { BaseLogsPublicInterface } from '@/modules/open-telemetry/types';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import CarOpenTelemetryService, { CarOpenTelemetryServiceS } from '../../car.open-telemetry.service';

/**
 * Mixin to track user's staing duration on a page
 */
@Component
export default class BaseOtelMixin extends Vue implements BaseLogsPublicInterface {
    @Inject(CarOpenTelemetryServiceS) protected otelService!: CarOpenTelemetryService;
    protected postfix = '';

    private get name() {
        return `${this.$route.name!}.${this.postfix}`;
    }

    startSpan(prefix?: LOGTYPE) {
        if (!this.name) {
            throw new Error('Span have to be inited.');
        }

        this.otelService.startSpan({ name: this.name, prefix });
        this.addEvent('start', prefix);
    }

    endSpan(prefix?: LOGTYPE, options?: { sendLogs?: boolean, payload?: Record<string, any> }) {
        this.addEvent('finish', prefix);
        this.otelService.endSpan({ name: this.name, prefix }, options);
    }

    addEvent(eventName: string, prefix?: LOGTYPE) {
        this.otelService.addEvent({ name: this.name, prefix }, eventName);
    }

    preparePayload(data: Record<string, any>) {
        return {
            'cx.action.filterBy': this.otelService.buildFilterBy(data),
        };
    }

    protected isSpanExists(prefix: LOGTYPE) {
        return this.otelService.isSpanExists({ name: this.name, prefix });
    }
}
