import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import BaseOtelMixin from '@/modules/cars/modules/open-telemetry/mixins/common/base-otel.mixin';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import CarsAlertService, { CarsAlertServiceS } from '@/modules/cars/car.alert.service';

/**
 * Mixin for rates pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class SippCodeMappingAlertsSettingsPageOtelLogs extends Mixins(BaseOtelMixin) {
    @Inject(CarsAlertServiceS) carsAlertService!: CarsAlertService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.postfix = 'car-user-settings.sipp-code-mapping-alerts';
        this.startSpan(SippCodeMappingAlertsSettingsPageOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', SippCodeMappingAlertsSettingsPageOtelLogs.spanPrefix);
        this.carsAlertService.storeState.loading.whenLoadingFinished()
            .then(_ => {
                this.addEvent('gotRespnose', SippCodeMappingAlertsSettingsPageOtelLogs.spanPrefix);
                this.endSpan(SippCodeMappingAlertsSettingsPageOtelLogs.spanPrefix, { sendLogs: true, payload: this.preparePayload(this.carsAlertService.storeState.settings) });
            });
    }
}
