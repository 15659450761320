


































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import * as _ from 'lodash';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import CompetitorAlertFilter from '@/modules/user/components/competitor-alert-filter.vue';
import CarRateAlertConfigsPopup from '@/modules/cars/modules/settings/components/cars-rate-alert-configs-popup.vue';
import CarsRateAlertService, { CarsRateAlertServiceS, notificationMessages } from '@/modules/cars/car.rate-alert.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import { IConfigDataTable } from '@/modules/cars/modules/settings/interfaces/alert-interfaces';
import CustomMenu from '@/modules/cars/modules/settings/components/custom-menu.vue';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import CarsRateAlertConfigurationModel from '@/modules/cars/models/cars-rate-alert-configuration.model';
import CarsCompsetsAlertService, { CarsCompsetsAlertServiceS } from '@/modules/cars/car.compsets-alert.service';
import CarsCompetitorsAlertService, { CarsCompetitorsAlertServiceS } from '@/modules/cars/car.competitors-alert.service';
import CarsBrandAlertService, { CarsBrandAlertServiceS } from '@/modules/cars/car.brand-alert.service';
import CarsTrendAlertService, { CarsTrendAlertServiceS } from '@/modules/cars/car.trend-alert.service';
import { CarAlertTypeList } from '../enums/alerts.enum';

@Component({
    components: {
        CustomSwitch,
        CompetitorAlertFilter,
        CustomMenu,
        CustomLoader,
        CarRateAlertConfigsPopup,
    },
})

export default class CarRateAlertSettings extends Vue {
    @Inject(CarsRateAlertServiceS) private carsRateAlertService!: CarsRateAlertService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(NotifyServiceS) private notifyService!: NotifyService;
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;
    @Inject(CarsCompsetsAlertServiceS) private carsCompsetsAlertService!: CarsCompsetsAlertService;
    @Inject(CarsCompetitorsAlertServiceS) private carsCompetitorsAlertService!: CarsCompetitorsAlertService;
    @Inject(CarsBrandAlertServiceS) private carsBrandAlertService!: CarsBrandAlertService;
    @Inject(CarsTrendAlertServiceS) private carsTrendAlertService!: CarsTrendAlertService;

    @Prop({ type: String })
    public alertType!: string;

    public readonly trendValues: Record<number, string> = {
        1: 'Any',
        2: 'Higher',
        3: 'Lower',
    };

    public isPopupOpen: boolean = false;
    public configId: string = '';

    get alertService() {
        switch (this.alertType) {
            case CarAlertTypeList.COMPSET_DIFF:
                return this.carsCompsetsAlertService;
            case CarAlertTypeList.COMPETITORS_DIFF:
                return this.carsCompetitorsAlertService;
            case CarAlertTypeList.BRAND_DIFF:
                return this.carsBrandAlertService;
            case CarAlertTypeList.TREND_DIFF:
                return this.carsTrendAlertService;
            default:
                return this.carsRateAlertService;
        }
    }

    get tableData() {
        const { data } = this.alertService;

        if (!data || !data.length) {
            return [];
        }
        const configData: IConfigDataTable[] = [];
        data.forEach(config => {
            configData.push({
                id: config.id,
                percentage: `${(config as CarsRateAlertConfigurationModel).ratio || 'Any'}`,
                location: config.locationIds.map(locationId => {
                    const location = this.carsSharedService.getLocationNameById(locationId) || { locationName: ' ' };
                    return location.locationName;
                }).join(', '),
                locationsCount: config.locationIds.length,
                competitors: config.competitors.length ? config.competitors.join(', ') : '',
                country: this.getCountry(config.locationIds),
                lok: config.lors.join(', '),
                pos: config.pos.join(', '),
                carCategory: config.carCategories.join(', '),
                type: config.fuelType.join(', '),
                source: config.dataSource.join(', '),
                pickupDate: config.pickupDates.dynamic ? [config.pickupDates.dynamic.join(' - ')] : config.pickupDates.fixed,
                status: config.isActive,
                trend: config.trend,
            });
        });
        return configData;
    }

    public getCountry(locationIds: string[]) {
        if (locationIds && locationIds.length) {
            const countries = locationIds.map(id => this.carsFiltersService.getCountryByPickUpLocationId(id));
            if (_.uniq(countries).length === 1) {
                return countries[0] || COUNTRIES_ANY;
            }
        }
        return COUNTRIES_ANY;
    }

    public get menuItems() {
        return [
            {
                text: 'Edit',
                action: this.handleUpdateButton,
            },
            {
                text: 'Remove',
                action: this.handleRemoveButton,
            },
        ];
    }

    async updateChangeState(configId: string, status: boolean) {
        await this.alertService.updateAlertConfigurationStatus(configId, status);
        this.alertService.resetLoadings();
    }

    async handleUpdateButton(configId: string) {
        this.isPopupOpen = true;
        this.configId = configId;
    }

    async handleRemoveButton(configId: string) {
        await this.alertService.deleteAlertConfiguration(configId);
        this.notifyService.success(notificationMessages.REMOVED_SUCCESS);
        this.alertService.resetLoadings();
    }

    closePopup() {
        this.isPopupOpen = false;
    }
}
