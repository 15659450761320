import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import BaseOtelMixin from '@/modules/cars/modules/open-telemetry/mixins/common/base-otel.mixin';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import CarsRateAlertService, { CarsRateAlertServiceS } from '@/modules/cars/car.rate-alert.service';

/**
 * Mixin for rates pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class AlertsSettingsPageOtelLogs extends Mixins(BaseOtelMixin) {
    @Inject(CarsRateAlertServiceS) private carsRateAlertService!: CarsRateAlertService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.postfix = 'car-user-settings.alerts-notifications';
        this.startSpan(AlertsSettingsPageOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', AlertsSettingsPageOtelLogs.spanPrefix);
        this.carsRateAlertService.storeState.loading.whenLoadingFinished()
            .then(_ => {
                this.addEvent('gotRespnose', AlertsSettingsPageOtelLogs.spanPrefix);
                this.endSpan(AlertsSettingsPageOtelLogs.spanPrefix, { sendLogs: true, payload: this.preparePayload(this.carsRateAlertService.storeState.settings) });
            });
    }
}
